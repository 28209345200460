import React from "react"
import Container from "react-bootstrap/Container"

import styles from "./footer.module.css"

const Footer = () => (
  <Container fluid={true} className={styles.footer}>
    <footer>
      <strong>© {new Date().getFullYear()} Interfacer Limited.</strong>
      <br />
      Registered in England and Wales, 7592154
    </footer>
  </Container>
)

export default Footer
