import PropTypes from "prop-types"
import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header
    style={{
      minHeight: "56px",
    }}
  >
    <Navbar bg="light" expand="md" fixed="top">
      <Link to="/" className="navbar-brand">
        {siteTitle}
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Link to="/#experience" className="nav-link">
            Experience
          </Link>
          <Link to="/#philosophy" className="nav-link">
            Philosophy
          </Link>
          <Link to="/talk-to-us" className="nav-link">
            Talk to us
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
